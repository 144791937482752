import auth0 from 'auth0-js';
import Cookies from 'js-cookie';
import { navigate } from 'gatsby';
import { useIsMobile } from './utils/useIsMobile';
import { red } from '@mui/material/colors';
// A simple check to determine whether this is build time or run time.
// window does not exist at build time, so some methods can only be used at run
// time.
export const isBrowser = typeof window !== 'undefined';

// Checks to see if the disable_auth flag is set. When set, users will not be
// able to access login / account / register pages.
export const isAuthDisabled = process.env.DISABLE_AUTH === 'true';

// Only create a new WebAuth instance if we are in the browser and all necessary
// environment variables are set.
const auth =
  isBrowser &&
  process.env.AUTH0_DOMAIN &&
  process.env.AUTH0_CLIENTID &&
  process.env.AUTH0_CALLBACK
    ? new auth0.WebAuth({
        domain: process.env.AUTH0_DOMAIN,
        clientID: process.env.AUTH0_CLIENTID,
        redirectUri: process.env.AUTH0_CALLBACK,
        responseType: 'token id_token',
        scope: 'openid profile email'
      })
    : {};

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false
};
//const flags = useFlags();
let user = {};

/**
 * If the user has previously logged in on the site, we'll want to try silent
 * authentication.
 *
 * @returns {Bool} Whether shouldTrySilentAuth is true in localStorage.
 */
export const shouldTrySilentAuth = () => {
  if (!isBrowser) {
    return;
  }

  return localStorage.getItem('shouldTrySilentAuth') === 'true';
};

/**
 * Check to see if a user is authenticated.
 *
 * @returns {Boolean} Whether the user has an access token or if Cypress has set a cookie to dummy logged in status.
 */
export const isAuthenticated = () => {
  if (!isBrowser) {
    return;
  }

  return tokens.accessToken !== false || Cookies.get('cypress_auth0_cookie');
};

/**
 * Trigger a login redirect.
 * @returns {void}
 */
export const login = () => {
  if (!isBrowser) {
    return;
  }

  auth.authorize();
};

/**
 * Trigger a login redirect for an enterprise connection.
 * @param {String} connection The connection name.
 * @returns {void}
 */
export const loginEnterprise = (connection = '') => {
  if (!isBrowser) {
    return;
  }

  auth.authorize({ connection });
};

/**
 * Sets the path we want to redirect to after a successful login.
 * @param {String} path The path name.
 * @returns {void}
 */
export const setSuccessLocation = (path) => {
  const pathBlackList = [
    '/callback',
    '/error',
    '/login',
    '/maintenance',
    '/construction',
    '/register',
    '/register/policy',
    '/register/success/'
  ];

  if (path === 'learning-safari') {
    localStorage.setItem('redirectLocation', '/learning/');
  } else if (path === 'teamlearning-safari') {
    localStorage.setItem('redirectLocation', '/account/learning');
  } else if (path && !pathBlackList.includes(path)) {
    localStorage.setItem('redirectLocation', path);
  } else {
    localStorage.setItem('redirectLocation', '/account');
  }
};

/**
 * Log out the user.
 * @returns {void}
 */
export const logout = () => {
  // Remove the localStorage flag.
  localStorage.removeItem('shouldTrySilentAuth');

  if (isInactive()) {
    auth.logout({ returnTo: process.env.ACCESS_DENIED_LINK });
  } else {
    auth.logout({ returnTo: process.env.AUTH0_LOGOUT });
  }
};

/**
 * The setSession function checks for errors and adds the tokens and expiration
 * to the tokens object. It also assigns the idTokenPayload to the user object.
 * You'll retrieve that using getProfile from a component.
 *
 * @param {function} cb A callback function.
 * @param {bool} isSilentAuth true if this is a silent auth flow.
 * @returns {void}
 */

const setSession =
  (cb = () => {}, isSilentAuth = false) =>
  (err, authResult) => {
    const isMobile = useIsMobile();
    const advicehubRedirect = process.env.ADVICE_HUB_REDIRECT;

    // Handle errors.
    if (err) {
      const desc = err.errorDescription;

      // If access is denied due to an unverified email, display a message to the
      // user.
      if (desc === 'Please verify your email before logging in.') {
        const message =
          'You must verify your email before logging in. Please check your email for a verification link.'; // eslint-disable-line max-len

        // Pass message to the error page.
        navigate('/error', { state: { message } });
        // Return false to SessionCheck.handleCheckSession
        cb(false);
        return;
      }

      // If this is not a silent auth flow and not a login_required error,
      // display the default error page.
      if (!isSilentAuth && err.error !== 'login_required') {
        navigate('/error');
      }

      // If this is not a silent auth flow and is a login_required error,
      // send the user to the login screen.
      if (!isSilentAuth && err.error === 'login_required') {
        navigate('/login');
      }

      // Return false to SessionCheck.handleCheckSession
      cb(false);
      return;
    }

    // If the response has everything we need, save them for later
    if (authResult && authResult.accessToken && authResult.idToken) {
      let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
      // Save token information for later.
      tokens.accessToken = authResult.accessToken;
      tokens.idToken = authResult.idToken;
      tokens.expiresAt = expiresAt;
      // user = authResult.idTokenPayload;
      // getting the profile from the idTokenPayload
      const profile =
        authResult.idTokenPayload['https://www.magmutual.com/Profile'];

      user = {
        ...authResult.idTokenPayload,
        profile
      };

      let prevLocationRedirect = localStorage.getItem('redirectLocation');
      let axiaUser = user.email.includes('@axiawh.com') ? true : false;
      let nonRedirects = ['/learning/', '/quote/', '/account/learning'];

      if (advicehubRedirect === 'true') {
        nonRedirects = [...nonRedirects, '/account/'];
      }

      const customerPortal = ['M3 Customer'];
      const agentPortal = ['Agent', 'Agency Manager', 'Agency Administrator'];
      const adviceAccessLevel = [
        'Hub - Customer New',
        "Hub - Customer New + Worker's Comp"
      ];

      try {
        // Check if the user is inactive first and redirect them to /accessdenied if true
        if (isInactive() === true) {
          console.log('User is inactive, redirecting to /accessdenied');
          setSuccessLocation('/logout');
        } else if (
          prevLocationRedirect === '/globalpreference/' &&
          agentPortal.includes(user['https://www.magmutual.com/Profile'])
        ) {
          console.log(
            'Condition met: prevLocationRedirect is /globalpreference and user is an agent. Setting success location to /preferenceAgent'
          );
          setSuccessLocation('/globalpreferenceagent/');
        } else if (
          prevLocationRedirect === '/globalpreference/' &&
          customerPortal.includes(user['https://www.magmutual.com/Profile'])
        ) {
          console.log(
            'Condition met: prevLocationRedirect is /globalpreference and user is a customer. Setting success location to /preferenceCustomer'
          );
          setSuccessLocation('/globalpreferencecustomer/');
        } else if (
          customerPortal.includes(user['https://www.magmutual.com/Profile']) &&
          !nonRedirects.some((path) => prevLocationRedirect.includes(path)) &&
          !axiaUser
        ) {
          console.log(
            'Condition met: setting success location to /customerportal'
          );
          setSuccessLocation('/customerportal');
        } else if (
          agentPortal.includes(user['https://www.magmutual.com/Profile']) &&
          !nonRedirects.some((path) => prevLocationRedirect.includes(path)) &&
          !axiaUser
        ) {
          console.log(
            'Condition met: setting success location to /agentportal'
          );
          setSuccessLocation('/agentportal');
        } else {
          console.log(
            'Condition not met: setting success location to /account'
          );
          // Uncomment this line to set the fallback location
          // setSuccessLocation('/account');
        }
      } catch (error) {
        console.error('Path', error);
      }

      if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'userLoggedIn',
          auth0UserId: user.sub
        });
      }
      try {
        // Add user info to logging service logrocket
        if (process.env.GATSBY_LOGROCKET_APP_ID) {
          // LogRocket.identify(user.sub, user);
        }
      } catch (error) {
        console.error(error);
      }

      // If the user has not previously authenticated.
      // (aka, this is not a silentAuth flow)
      if (!isSilentAuth) {
        // Set a flag to remember to try silentAuth next time.
        localStorage.setItem('shouldTrySilentAuth', 'true');
        // Navigate to the page the user was on when they initiated login.
        const redirectLocation = localStorage.getItem('redirectLocation');
        // Remove the redirectLocation from localStorage.
        localStorage.removeItem('redirectLocation');
        navigate(redirectLocation);
      }

      cb(true);
    }
  };

/**
 * Check the user's session if localStorage says they have previously logged in.
 * This is only called in the SessionCheck component, which is called at the
 * Gatsby root, so it only runs when the application mounts:
 * on initial page load or browser refresh.
 *
 *
 * @param {Function} callback - A callback function to handle the result.
 * @returns {void}
 */
export const silentAuth = (callback) => {
  if (!shouldTrySilentAuth()) {
    return callback(false);
  }
  auth.checkSession({}, setSession(callback, true));
};

/**
 * The handleAuthentication function calls Auth0's parseHash function, which
 * will parse the tokens from the location hash.
 *
 * @returns {void}
 */
export const handleAuthentication = () => {
  if (!isBrowser) {
    return;
  }

  // After tokens are parsed, setSession is called.
  auth.parseHash(setSession());
};

/**
 * Return user data from Auth0.
 * @returns {Object} The user data from Auth0.
 */
export const getProfile = () => {
  return user;
};

/**
 * Returns the access token from Auth0.
 * @returns {String} The user access token from Auth0.
 */
export const getIdToken = () => tokens.idToken;

/**
 * Is the user an active customer or not.
 *
 * @returns {Bool} true if the user is a customer.
 */
export const isCustomer = () => {
  return (
    user['https://www.magmutual.com/sfcustomerstatus'] === 'Customer' ||
    user['https://www.magmutual.com/sfcustomerstatus'] === 'M3 Customer'
  );
};
/**
 * Is the user an active prospect?
 *
 * @returns {Bool} true if the user is a Prospect.
 */
export const isProspect = () => {
  return user['https://www.magmutual.com/sfcustomerstatus'] === 'Prospect';
};

/**
 * Is the user an active prospect?
 *
 * @returns {Bool} true if the user is undefined.
 */
export const isUnidentified = () => {
  return user['https://www.magmutual.com/sfcustomerstatus'] === 'Unidentified';
};

export const isPurcProgAdmin = () => {
  return (
    user['https://www.magmutual.com/sfcustomerstatus'] === 'Customer' &&
    user['https://www.magmutual.com/sfaccesslevel'] ===
      'Purchasing Program Admin'
  );
};

export const isBillingAdmin = () => {
  return user['https://www.magmutual.com/sfaccesslevel'] === 'Billing';
};

export const isOneModelRole = () => {
  return user['https://www.magmutual.com/sfroles'].includes('OneModel');
};

/*export const hasUTDPolicies = () => {
   const allowedPolicies = ['PSL', 'ESP', 'HFL', 'HPL', 'HUL'];
   for (let policy of user['https://www.magmutual.com/sfPolicies']) {
     if (allowedPolicies.includes(policy)) {
       return true;
     }
   }
   return false;
 };*/

/**
 * Practice admins that are customers should have access to all the "Team"
 * and "Team Learning" tabs in the account section of the site.
 *
 * @returns {Bool} True if the user is a customer and has an admin role.
 */
export const isPracticeAdmin = () => {
  return (
    isCustomer() &&
    user['https://www.magmutual.com/sfroles'].includes('Administrator')
  );
};

/**
 * Silver Customers do not have access to UpToDate, Policy View, or Pay my Bill.
 *
 * @returns {Bool} True if the user is a customer and is a Silver Customer
 */
export const isCustomerSilver = () => {
  return (
    isCustomer() &&
    user['https://www.magmutual.com/sfroles'].includes('Customer Silver')
  );
};

/**
 * Determines if a user is an agent.
 *
 * @returns {Bool} true if the user is an agent.
 */
export const isAgent = () => {
  return (
    user['https://www.magmutual.com/sfcustomerstatus'] === 'Agent' ||
    (user['https://www.magmutual.com/sfroles'] &&
      user['https://www.magmutual.com/sfroles'].includes('Agent'))
  );
};

/**
 * Determines is a user is a USACS user. They have some limited access within
 * the account section of the site.
 *
 * @returns {Bool} true if the user is a USACS user.
 */
export const isUSACS = () =>
  user && user.sub && user.sub.includes('USACS-SAML-Authentication');

/**
 * A helper method for determining if the user is Retired/Deleted.
 * Can be used to hide / show content depending on user.
 * @returns {Bool} true if the user is retired/deleted
 */
export const isRetiredOrDeleted = () =>
  user['https://www.magmutual.com/sfroles'] &&
  user['https://www.magmutual.com/sfroles'].includes('Retired/Deleted');

/**
 * A helper method for determining if the user is inactive.
 * Can be used to hide / show content depending on user.
 * @returns {Bool} true if the user is inactive.
 */
export const isInactive = () => {
  if (user['https://www.magmutual.com/sfstatus'] === 'Inactive') {
    return true;
  } else {
    return false;
  }
};

/**
 * Returns the audience type. This is used in the Learning Center for gated
 * content. Content in the learning center can be gated so certain users
 * can only see a preview. This function lets us determine what content a
 * user should be able to see.
 *
 * @returns {String} The audience for a user.
 */
export const getAudience = () => {
  if (
    user['https://www.magmutual.com/sfcustomerstatus'] === 'Customer' &&
    user['https://www.magmutual.com/sfroles'].includes('MGL Policyholder')
  ) {
    return 'MGL Policyholder';
  }

  // If the user is a customer but not an MGL Policyholder, they are a regular
  // policyholder.
  if ((isCustomer() || isAgent()) && !isInactive()) {
    return 'Policyholder';
  }

  // If the user is not an mgl policyholder or a policyholder, but they are
  // logged in, then they are a prospect.
  if (isAuthenticated() || isInactive() || isUnidentified()) {
    return 'Prospect';
  }

  return 'Public';
};

/**
 * Trigger a password reset email to be sent so a user can change their
 * password.
 *
 * @param {Function} callback A method to do something with the response.
 * @returns {void}
 */
export const changePassword = (callback = () => {}) => {
  auth.changePassword(
    {
      connection: 'Username-Password-Authentication',
      email: user.email
    },
    (err, resp) => {
      if (err) {
        callback(err.message);
      } else {
        callback(resp);
      }
    }
  );
};
